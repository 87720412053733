import React, { useEffect } from 'react';
import { Footer } from '../../components/footer';
import { Header } from '../../components/header';
import { InstallBanner } from '../../components/install-banner';
import { Layout, ContentContainer } from '../../components/layout';
import { AboutUs } from '../../components/sections/user/about-us';
import { Features } from '../../components/sections/user/features';
import { Intro } from '../../components/sections/user/intro';
import { Investors } from '../../components/sections/user/inverstors';
import { Numbers } from '../../components/sections/user/numbers';
import { Recommendation } from '../../components/sections/user/recommendation';
import { Reviews } from '../../components/sections/user/reviews';
import { Stories } from '../../components/sections/user/stories';
import { StoriesLibrary } from '../../components/sections/user/stories-library';
import { v4 as uuidv4 } from 'uuid';

export const HomePage = () => {
    useEffect(() => {
		const { search } = window.location
		const params = new URLSearchParams(search)
		const ttclid = params.get('ttclid')

		if (ttclid) {
			localStorage.setItem('ttclid', ttclid)
		}
	}, [])

	useEffect(() => {
		const didRedirect = sessionStorage.getItem('didRedirect') === '1'
		if (!didRedirect) {
			sessionStorage.setItem('didRedirect', '1')
			window.open(window.location.href, '_top', 'noopener,noreferrer')
		}
	}, [])
    useEffect(() => {
		const prevUserId = localStorage.getItem('amp-user-id')
		window.id=prevUserId
		if (!prevUserId) {
			const userId = uuidv4()
			localStorage.setItem('amp-user-id', userId)
			window.id = userId
		}
	}, [])

	useEffect(() => {
		window.history.scrollRestoration = 'manual'
	}, [])

    return (
        <Layout>
            <Header />
            <ContentContainer>
                <Intro />
                <StoriesLibrary />
                <Features />
                <Recommendation />
                <Reviews />
                <AboutUs />
                <Numbers />
                <Stories />
                <Investors />
                <Footer />
            </ContentContainer>
            <InstallBanner />
        </Layout>
    )
}
import React from 'react';

import * as Styled from './styled';

const Rating = () => (
    <Styled.Rating>
        <Styled.Star />
        <Styled.Star />
        <Styled.Star />
        <Styled.Star />
        <Styled.Star />
    </Styled.Rating>
)

export const Reviews = () => (
    <Styled.Container>
        <Styled.RatingBlock>
            <Rating />
            <Styled.Review>
                I like the different stories they have!
            </Styled.Review>
            <Styled.From>
                Charline
            </Styled.From>
            <Styled.At>
                05/29/2022 on Google Play
            </Styled.At>
        </Styled.RatingBlock>

        <Styled.RatingBlock>
            <Rating />
            <Styled.Review>
                Loving this app.
            </Styled.Review>
            <Styled.From>
                Tanner
            </Styled.From>
            <Styled.At>
                05/29/2022 on Google Play
            </Styled.At>
        </Styled.RatingBlock>

        <Styled.RatingBlock>
            <Rating />
            <Styled.Review>
                I enjoy reading
            </Styled.Review>
            <Styled.From>
                Brynn
            </Styled.From>
            <Styled.At>
                05/29/2022 on Google Play
            </Styled.At>
        </Styled.RatingBlock>
    </Styled.Container>
)
import styled from 'styled-components'

export const Container = styled.section`
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export const Title = styled.h2`
    margin-bottom: 60px;

    font-weight: 700;
    font-size: 72px;
    line-height: 80px;
    letter-spacing: -0.04em;
 
    color: #ECECEC;

    @media screen and (max-width: 550px) {
        max-width: 320px;
        margin-bottom: 44px;
        text-align: center;
        font-size: 38px;
        line-height: 112%;
    }
`

export const Steps = styled.div`
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 550px) {
        flex-direction: column;
        align-items: center;
    }
`

export const Step = styled.div`
    width: 27%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 550px) {
        width: 90%;
        margin-bottom: 44px;
    }
`;

export const StepImage = styled.img`
    width: 100%;
    margin-bottom: 28px;

    @media screen and (max-width: 550px) {
        width: 224px;
        height: 168px;
    }
`;

export const StepTitle = styled.h3`
    margin-bottom: 28px;

    font-weight: 700;
    font-size: 44px;
    line-height: 60px;    
    text-align: center;
    letter-spacing: -0.03em;

    color: #ECECEC;

    @media screen and (max-width: 550px) {
        margin-bottom: 24px;
        font-size: 28px;
        line-height: 128%;
    }
`;

export const StepDescription = styled.p`
    font-size: 20px;
    line-height: 32px;
    text-align: center;

    color: #BCC4D0;

    @media screen and (max-width: 550px) {
        font-size: 16px;
        line-height: 150%;   
    }
`
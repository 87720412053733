import React from "react";

import * as Styled from './styled'

export const Recommendation = () => {
    return (
        <Styled.Container>
            <Styled.StarIcon />
            <Styled.TopContainer>
                <Styled.Heading>The best recommendation for you</Styled.Heading>
                <Styled.Description>Overwhelmed with what story to read next? Our story recommendation system delivers story suggestions based on your interest and reading behavior. This recommendation takes into account many parameters such as book content and book quality by filtering user reviews. </Styled.Description>
            </Styled.TopContainer>
            <Styled.Banner src="/img/new-recommendations.png" />
            <Styled.BannerMobile src="/img/new-recommendations-mobile.png" />
        </Styled.Container>
    )
}
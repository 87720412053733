import React from "react";

import * as Styled from './styled';

export const AboutUs = () => (
    <Styled.Container>
        <Styled.Image src="/img/about-us.png" />
        <Styled.Title>Who Are We?</Styled.Title>
        <Styled.Text>Stories have always been there to offer us a quick escape, a pause from our mundane life and a chance to enjoy a different realm.</Styled.Text>
        <Styled.Subtitle>Our goal:</Styled.Subtitle>
        <Styled.Text>Our product aims to open a new era of creativity for creators who are looking for a new space where they can do what they love most and earn as well through our licensing program. We accept previously published works and we’re open to short-term or long-term collaborations. We believe that a partnership is a two-way street, thus we always root for our authors' growth and we aim to help them maximize the potential of their works.</Styled.Text>
    </Styled.Container>
)
import React from 'react';
import { WRITERS_PLATFORM_LINK } from '../../../../constants';

import * as Styled from './styled'

export const BecomeCreator = () => (
    <Styled.Container>
        <Styled.CreatorIcon />
        <Styled.TextContent>
            <Styled.Title>
                Be a Passion Creator now
            </Styled.Title>
            <Styled.Text>
                Are you a passionate creator? Do you love writing down your emotions and turn it into remarkable stories? Have you ever dreamed of reaching more readers all over the world? Join us and monetize your work!
            </Styled.Text>
            <Styled.Button as="a" href={WRITERS_PLATFORM_LINK}>
                Become a Creator
            </Styled.Button>
        </Styled.TextContent>
    </Styled.Container>
)
import styled from "styled-components";

import { ReactComponent as AppleStoreLogo } from '../../../../assets/svg/apple.svg'
import { ReactComponent as GoogleStoreLogo } from '../../../../assets/svg/google.svg'

export const AppleStore = styled(AppleStoreLogo)`
    margin-right: 13px;
`;

export const GoogleStore = styled(GoogleStoreLogo)`
    margin-right: 13px;
    width: 24px;
    height: 24px;
`;

export const Wrapper = styled.section`
    width: 100%;
    padding-top: 152px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 550px) {
        align-items: flex-start;
        padding-top: 74px;
        height: fit-content;
    }
`

export const Container = styled.div`
    position: relative;
    width: 100%;

    @media screen and (max-width: 550px) {
        justify-content: center;
        align-items: center;
    }
`

export const ContentContainer = styled.div`
    width: 50%;

    @media screen and (max-width: 550px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`

export const Heading = styled.h1`
    width: 100%;
    margin-bottom: 27px;
    font-weight: 700;
    font-size: 88px;
    line-height: 103%;
    letter-spacing: -0.03em;

    color: #ECECEC;

    @media screen and (max-width: 1050px) {
        font-size: 70px;
        line-height: 103%;
    }


    @media screen and (max-width: 800px) {
        font-size: 60px;
        line-height: 103%;
    }

    @media screen and (max-width: 550px) {
        font-size: 44px;
        line-height: 112%;
        margin-bottom: 16px;
        max-width: 344px;
        text-align: center;
    }
`

export const Subheading = styled.span`
    font-size: 22px;
    line-height: 33px;
    letter-spacing: -0.02em;

    color: #BCC4D0;

    @media screen and (max-width: 550px) {
        width: 100%;
        font-size: 19px;
        line-height: 142%;
        text-align: center;
    }
`

export const ButtonsContainer = styled.div`
    margin-top: 36px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 950px) {
        align-items: flex-start;
        flex-direction: column;
    }

    @media screen and (max-width: 550px) {
        display: none;
    }
`

export const StoreButton = styled.button`
    z-index: 999999;
    margin-right: 20px;
    display: flex;
    padding: 12px 32px;
    min-width: 190px;
    text-decoration: none;

    align-items: center;
    justify-content: center;

    border-radius: 99999px;

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;

    color: #0A0A0F;
    background: #ECECEC;
    border: none;

    @media screen and (max-width: 950px) {
        margin-bottom: 16px;
    }
`

export const SideImage = styled.img`
    position: absolute;
    top: 50%;
    right: -100px;
    width: 70vw;
    max-width: 915px;
    transform: translateY(-50%);

    @media screen and (max-width: 550px) {
        display: none;
    }
`

export const MobileImage = styled.img`
    margin-top: -50px;

    display: none;

    width: calc(100% + 32px);
    margin-left: -16px;


    @media screen and (max-width: 550px) {
        display: block;
    }
`;
import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    bottom: 16px;
    display: none;
    width: calc(100% - 24px);
    padding: 12px 15px;
    background: #212124;
    border-radius: 16px;
    align-items: center;
    z-index: 999999;
    transform: translate(0px, 0px);

    @media screen and (max-width: 550px) {
        display: flex;
    }
`;

export const Logo = styled.img`
    width: 50px;
    height: 50px;
    margin-right: 12px;
`;

export const TextContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Title = styled.h2`
    margin-bottom: 2px;
    font-weight: 600;
    font-size: 17px;
    line-height: 112%; 
    letter-spacing: -0.03em;

    color: #FFFFFF;
`

export const Subtitle = styled.span`
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.02em;

    color: #64738A;
`;

export const Button = styled.button`
    width: fit-content;    

    padding: 8px 16px;
    border-radius: 99999px;
    background-color: #f00a9c;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-decoration: none;

    color: #FFFFFF;
    border: none;
`
import styled from "styled-components";

import { ReactComponent as Downloads } from '../../../../assets/svg/downloads.svg'
import { ReactComponent as Reads } from '../../../../assets/svg/reads.svg'
import { ReactComponent as Books } from '../../../../assets/svg/books.svg'

export const Container = styled.section`
    padding: 80px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: 550px) {
        padding: 40px 0;
    }
`

export const Title = styled.h2`
    margin-bottom: 60px;
    font-weight: 700;
    font-size: 44px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.03em;

    color: #ECECEC;

    @media screen and (max-width: 550px) {
        margin-bottom: 50px;
        font-size: 28px;
        line-height: 128%;
    }
`

export const MetricsRow = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;

    @media screen and (max-width: 550px) {
        flex-direction: column;
    }
`

export const Metric = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 550px) {
        margin-bottom: 50px;
    }
`

export const DownloadsIcon = styled(Downloads)`
    margin-bottom: 40px;
    min-height: 76px;
    min-width: 76px;

    @media screen and (max-width: 550px) {
        min-width: 72px;
        min-height: 72px;
        margin-bottom: 16px;
    }
`;
export const ReadsIcon = styled(Reads)`
    margin-bottom: 40px;
    min-height: 76px;
    min-width: 76px;

    @media screen and (max-width: 550px) {
        min-width: 72px;
        min-height: 72px;
        margin-bottom: 16px;
    }
`;
export const BooksIcon = styled(Books)`
    margin-bottom: 40px;
    min-height: 76px;
    min-width: 76px;

    @media screen and (max-width: 550px) {
        min-width: 72px;
        min-height: 72px;
        margin-bottom: 16px;
    }
`;

export const Number = styled.span`
    margin-bottom: 28px;

    font-style: normal;
    font-weight: 700;
    font-size: 88px;
    line-height: 103%;
    text-align: center;
    letter-spacing: -0.03em;

    color: #ECECEC;

    @media screen and (max-width: 800px) {
        font-size: 64px;
        line-height: 112%;
    }

    @media screen and (max-width: 650px) {
        font-size: 54px;
        line-height: 112%;
    }
    

    @media screen and (max-width: 550px) {
        font-size: 44px;
        line-height: 112%;
        margin-bottom: 12px;
    }
`

export const NumberDescription = styled.span`
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    letter-spacing: -0.02em;

    color: #BCC4D0;

    @media screen and (max-width: 650px) {
        font-size: 20px;
        line-height: 112%;
    }

    @media screen and (max-width: 550px) {
        font-size: 19px;
        line-height: 142%;
    }
`
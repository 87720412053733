import styled from "styled-components";

export const Container = styled.section`
    margin-bottom: 40px;
    padding-top: 84px;
    padding-bottom: 50px;
    min-height: 100vh;
    width: 100%;
    display: flex;

    @media screen and (max-width: 850px) {
        flex-direction: column;
        height: fit-content;
    }
`

export const Feature = styled.div`
    flex-grow: 1;
    width: 48%;
    margin: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: 850px) {
        margin-bottom: 48px;
        width: 100%;

        &:last-child {
            margin-bottom: 140px;
        }
    }
`

export const FeatureImageContainer = styled.div`
    border-radius: 20px 20px 0px 0px;
    width: 100%;
    background-color: ${({ bg }) => bg};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-top: 78px;
    height: 50vh;

    @media screen and (max-width: 550px) {
        padding-top: 48px;
    }
`;

export const FeatureImage = styled.img`
    height: 100%;
`

export const FeatureTextContainer = styled.div`
    padding: 46px 78px;
    border-radius: 0px 0px 20px 20px;
    background: #15151A;
    height: 100%;

    @media screen and (max-width: 1100px) {
        padding: 32px 20px;        
    }
`;

export const FeatureHeading = styled.h3`
    margin-bottom: 16px;

    font-weight: 700;
    font-size: 44px;
    line-height: 60px;
    letter-spacing: -0.03em;
    color: #ECECEC;

    @media screen and (max-width: 750px) {
        font-size: 34px;
        line-height: 50px;
    }

    @media screen and (max-width: 550px) {
        margin-bottom: 20px;
        font-size: 28px;
        line-height: 128%;
    }
`

export const FeatureDescription = styled.p`
    font-size: 20px;
    line-height: 32px;
    color: #ECECEC;

    @media screen and (max-width: 750px) {
        font-size: 18px;
        line-height: 26px;
    }

    @media screen and (max-width: 550px) {
        font-size: 16px;
        line-height: 150%;
    }
`
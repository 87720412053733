import {
	getCampaignValue,
	getMediaSourceValue,
	setGenericParameter,
	getParameterFromURL,
	isFacebook,
	stringifyAfParameters,
} from './utils'

export class OneLinkUrlGenerator {
	constructor({
		oneLinkURL,
		pidKeysList = [],
		pidStaticValue = null,
		campaignKeysList = [],
		campaignStaticValue = null,
		pidOverrideList = [],
		gclIdParam = 'af_sub5',
	} = {}) {
		console.debug('Constructing OneLink URL generator')
		if (
			oneLinkURL === undefined ||
			typeof oneLinkURL !== 'string' ||
			oneLinkURL === ''
		) {
			console.error('OneLinkUrlGenerator: oneLinkURL arg invalid')
			return null
		}

		this.oneLinkURL = oneLinkURL
		// eslint-disable-next-line no-unused-expressions, no-sequences
		;(this.pidOverrideList = pidOverrideList),
			(this.gclIdParam = gclIdParam),
			(this.pidKeysList = pidKeysList),
			(this.pidStaticValue = pidStaticValue),
			(this.campaignKeysList = campaignKeysList),
			(this.campaignStaticValue = campaignStaticValue),
			// OneLink parameters
			(this.campaign = getCampaignValue(
				this.campaignKeysList,
				this.campaignStaticValue,
			))
		this.mediaSource = getMediaSourceValue(
			this.pidKeysList,
			this.pidStaticValue,
			this.pidOverrideList,
		)

		// af_js_web=true will be added to every URL that was generated through this script
		this.afParams = { af_js_web: 'true' }
	}

	generateUrl() {
		if (this.mediaSource == null) {
			console.debug('No valid pid value was found. URL will no be changed')
			return null
		}

		// User was redirected using af_r parameter on an AppsFlyer attribution link
		if (getParameterFromURL('af_redirect')) {
			console.debug(
				'This user comes from AppsFlyer by redirection and is ready to be attributed. \nKeep direct app store links.',
			)
			return null // in this case, the original store links in the install buttons stay the same
		}

		if (isFacebook()) {
			console.debug(
				"This user comes from a paid Facebook ad - don't do anything. \nKeep direct app store links.",
			)
			// the caller should make sure a return value of null will leave the original link
			return null
		}

		// Google Ads
		const pidValue = this.mediaSource
		const gclIdValue = getParameterFromURL('gclid')

		if (gclIdValue) {
			this.afParams[this.gclIdParam] = gclIdValue
			console.debug('This user comes from Google AdWords')

			const kwValue = getParameterFromURL('keyword')
			if (kwValue) {
				this.afParams.af_keywords = kwValue
				console.debug('There is a keyword associated with the ad')
			}
			// Other SRNs, custom networks and organic installs
		} else {
			console.debug('This user comes from SRN or custom network ')
		}
		const finalURL =
			this.oneLinkURL +
			'?pid=' +
			pidValue +
			'&c=' +
			this.campaign +
			stringifyAfParameters(this.afParams)
		console.debug(`Generated OneLink URL ${finalURL}`)
		return finalURL
	}

	// Setters for AF params
	setDeepLinkValue(deepLinkValueParam, deepLinkValue = null) {
		setGenericParameter(
			this.afParams,
			'deep_link_value',
			deepLinkValueParam,
			deepLinkValue,
		)
	}

	setChannel(channelParam, channelValue = null) {
		setGenericParameter(this.afParams, 'af_channel', channelParam, channelValue)
	}

	setAdset(adsetParam, adsetValue = null) {
		setGenericParameter(this.afParams, 'af_adset', adsetParam, adsetValue)
	}

	setAd(adParam, adValue = null) {
		setGenericParameter(this.afParams, 'af_ad', adParam, adValue)
	}

	setAfSub1(afSub1Param, afSub1Value = null) {
		setGenericParameter(this.afParams, 'af_sub1', afSub1Param, afSub1Value)
	}

	setAfSub2(afSub2Param, afSub2Value = null) {
		setGenericParameter(
			this.afParams,
			'this.afParams, af_sub2',
			afSub2Param,
			afSub2Value,
		)
	}

	setAfSub3(afSub3Param, afSub3Value = null) {
		setGenericParameter(this.afParams, 'af_sub3', afSub3Param, afSub3Value)
	}

	setAfSub4(afSub4Param, afSub4Value = null) {
		setGenericParameter(this.afParams, 'af_sub4', afSub4Param, afSub4Value)
	}

	setAfSub5(afSub5Param, afSub5Value = null) {
		setGenericParameter(this.afParams, 'af_sub5', afSub5Param, afSub5Value)
	}

	setCustomParameter(searchKey, customKey, customValue = null) {
		setGenericParameter(this.afParams, customKey, searchKey, customValue)
	}
}

import { createBrowserRouter, Navigate } from "react-router-dom";
import { ScrollToTop } from "../components/scroll-to-top";
import { AuthorsPage } from "./authors";
import { HomePage } from "./home";

export const router = createBrowserRouter([
    {
        path: '/',
        element:  <ScrollToTop><HomePage /></ScrollToTop>,
    },
    {
        path: '/creators',
        element: <ScrollToTop><AuthorsPage /></ScrollToTop>
    },
     {
        path: '*',
        element: <Navigate to='/' />
     }
])
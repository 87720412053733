import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { WRITERS_PLATFORM_LINK } from '../../../constants';

import * as Styled from './styled';
import { getOneLink } from '../../../utils/oneLink';

export const HeaderMobile = () => {
    const { pathname } = useLocation();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Styled.Container>
                <Styled.Wrapper>
                    <Styled.Shadow />
                    <Link to="/">
                        <Styled.Logo />
                    </Link>
                    <Styled.MenuButton onClick={() => setIsOpen(true)}>
                        <Styled.Burger />
                    </Styled.MenuButton>
                </Styled.Wrapper>
            </Styled.Container>
            <Styled.Drawer expanded={isOpen}>
                <Styled.DrawerContent>
                    <Styled.CloseButton onClick={() => setIsOpen(false)}>
                        <Styled.Cross />
                    </Styled.CloseButton>

                    <Styled.Navigation>
                        <Styled.Link to="/">Home</Styled.Link>
                        <Styled.Link as="a" href="mailto:support@passionapp.co">
                            Contact Us
                        </Styled.Link>
                        {pathname.includes('/creators') ? (
                            <Styled.Link as="a" href={WRITERS_PLATFORM_LINK} to="/creators">Become a Creator</Styled.Link>
                        ) : (
                            <Styled.Link to="/creators">Become a Creator</Styled.Link>
                        )}
                    </Styled.Navigation>

                    <Styled.Text>Get App</Styled.Text>

                    <Styled.StoreButton as="button" onClick={() => getOneLink()}>
                        <Styled.AppleStore fill="#0A0A0F" />
                        App Store
                    </Styled.StoreButton>
                    <Styled.StoreButton as="button" onClick={() => getOneLink()}>
                        <Styled.GoogleStore />
                        Google Play
                    </Styled.StoreButton>
                </Styled.DrawerContent>
                <Styled.DrawerShadow />
            </Styled.Drawer>
        </>
    )
}
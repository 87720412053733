import styled from "styled-components";

export const Layout = styled.div`
    width: 100%;
    background: #0A0A0F;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ContentContainer = styled.div`
    width: 100%;
    max-width: 1120px;
    padding: 0 40px;

    @media screen and (max-width: 550px) {
        padding: 0 16px;
    }
`
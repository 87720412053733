import React from "react";

import * as Styled from './styled'

export const Award = () => (
        <Styled.Container>
            <Styled.StarIcon />
            <Styled.TopContainer>
                <Styled.Heading>Award-Winning Creators</Styled.Heading>
                <Styled.Description>Meet some of Passion's Creators…</Styled.Description>
            </Styled.TopContainer>
            <Styled.Banner src="/img/award.png" />
            <Styled.Video controls src="/video/authors.mp4" />
            <Styled.Author>Chazminne Harrison</Styled.Author>
            <Styled.AuthorDescription>She’s the creator of the best selling story, His Rejection, in Passion.</Styled.AuthorDescription>
        </Styled.Container>
    )
import React from 'react';

import * as Styled from './styled';

export const Questions = () => (
    <Styled.Container>
        <Styled.QuestionsIcon />
        <Styled.Title id="contact-us">Still have questions?</Styled.Title>
        <Styled.Subtitle>Contact us to and we will answer all your questions</Styled.Subtitle>
        <Styled.Button as="a" href="mailto:support@passionapp.co">Contact Us</Styled.Button>
    </Styled.Container>
)
import React from 'react';

import * as Styled from './styled';

export const Numbers = () => (
    <Styled.Container>
        <Styled.Title>Passion in numbers</Styled.Title>
        <Styled.MetricsRow>
            <Styled.Metric>
                <Styled.DownloadsIcon />
                <Styled.Number>
                    350K
                </Styled.Number>
                <Styled.NumberDescription>
                    Downloads
                </Styled.NumberDescription>
            </Styled.Metric>

            <Styled.Metric>
                <Styled.ReadsIcon />
                <Styled.Number>
                    500K
                </Styled.Number>
                <Styled.NumberDescription>
                    Stories read
                </Styled.NumberDescription>
            </Styled.Metric>

            <Styled.Metric>
                <Styled.BooksIcon />
                <Styled.Number>
                    1000
                </Styled.Number>
                <Styled.NumberDescription>
                    Stories in the library
                </Styled.NumberDescription>
            </Styled.Metric>
        </Styled.MetricsRow>
    </Styled.Container>
)
import React, { useRef } from 'react';

import * as Styled from './styled';

export const AccordionItem = ({ question, answer, expanded, onClick }) => {
    const contentEl = useRef();

    return (
        <Styled.Container onClick={onClick}>
            <Styled.Item>
                <Styled.Question>
                    {question}
                </Styled.Question>

                <Styled.ArrowIcon expanded={expanded} />
            </Styled.Item>
            <Styled.AnswerContainer ref={contentEl} expanded={expanded} style={
                expanded
                    ? { height: contentEl.current.scrollHeight }
                    : { height: "0px" }}>
                <Styled.Answer>
                    {answer}
                </Styled.Answer>
            </Styled.AnswerContainer>
        </Styled.Container>
    )
}

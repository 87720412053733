import React from 'react';

import * as Styled from './styled';
import { getOneLink } from '../../utils/oneLink';

export const InstallBanner = () => (
    <Styled.Container>
        <Styled.Logo src="/img/app-logo.png" />
        <Styled.TextContainer>
            <Styled.Title>Passion</Styled.Title>
            <Styled.Subtitle>Read exclusive stories</Styled.Subtitle>
        </Styled.TextContainer>
        <Styled.Button as="button" onClick={getOneLink}>Get app</Styled.Button>
    </Styled.Container>
)
import React from 'react';

import * as Styled from './styled';

export const HowWorks = () => (
    <Styled.Container>
        <Styled.Title>How Passion Works</Styled.Title>
        <Styled.Steps>
            <Styled.Step>
                <Styled.StepImage src="/img/step-1.png" />
                <Styled.StepTitle>
                    Create
                </Styled.StepTitle>
                <Styled.StepDescription>
                    Share your unique voice and original story on Passion. Find the writing resources you need to craft a story only you can tell.
                </Styled.StepDescription>
            </Styled.Step>

            <Styled.Step>
                <Styled.StepImage src="/img/step-2.png" />
                <Styled.StepTitle>
                    Build
                </Styled.StepTitle>
                <Styled.StepDescription>
                    Establish a global fan base as your story gains readership and momentum. Connect with other like-minded creators through storytelling.
                </Styled.StepDescription>
            </Styled.Step>

            <Styled.Step>
                <Styled.StepImage src="/img/step-3.png" />
                <Styled.StepTitle>
                    Earn
                </Styled.StepTitle>
                <Styled.StepDescription>
                Create a new source of income by doing something that you love most. Maximize the potential of your stories!
                </Styled.StepDescription>
            </Styled.Step>
        </Styled.Steps>
    </Styled.Container>
)
import styled from "styled-components";

import { ReactComponent as QuoteIcon } from '../../../../assets/svg/quote.svg'
import { ReactComponent as Arrow } from '../../../../assets/svg/slider-arrow.svg'

export const Container = styled.section`
    position: relative;
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const Wrapper = styled.div`
    padding-top: 40px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
`

export const SlideContainer = styled.div`
    padding-left: 76px;
    padding-right: 76px;
    padding-bottom: 40px;
    width: 100%;
    height: 100%;
    max-width: 900px;

    display: flex;
    align-items: center;

    @media screen and (max-width: 950px) {
        flex-direction: column;   
        padding-bottom: 20px;     
    }

    @media screen and (max-width: 550px) {
        padding-left: 30px;
        padding-right: 30px;
    }
`;

export const BookCover = styled.img`
    max-width: 250px;
    margin-right: -40px;
    z-index: 1;

    @media screen and (max-width: 950px) {
        max-width: 171px;
        margin-bottom: -60px;
        margin-left: -12px;
    }
`

export const AuthorQuote = styled.div`
    padding: 60px 100px;
    background: #ECECEC;
    border-radius: 20px;

    @media screen and (max-width: 950px) {
        padding: 80px 24px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const Quote = styled(QuoteIcon)`
    margin-bottom: 12px;

    @media screen and (max-width: 550px) {
        width: 28px;
        height: 28px;
        margin-bottom: 24px;
    }
`;

export const Text = styled.p`
    margin-bottom: 52px;

    font-size: 22px;
    line-height: 33px;
    letter-spacing: -0.02em;

    color: #0A0A0F;

    @media screen and (max-width: 550px) {
        font-size: 16px;
        line-height: 150%;
        text-align: center;
    }
`

export const Author = styled.span`
    font-weight: 600;
    font-size: 25px;
    line-height: 35px;
    letter-spacing: -0.03em;

    color: #0A0A0F;

    @media screen and (max-width: 550px) {
        font-size: 20px;
        line-height: 142%;
    }
`

export const SliderNavigationButtonContainer = styled.button`
    background: transparent;
    border: none;
    position: absolute;
    ${({ right }) => right ? `right: 0;` : 'left: 0;'}
    top: 50%;
    transform: translateY(-100%);
    z-index: 99999;

    @media screen and (max-width: 950px) {
        transform: none;
        ${({ right }) => right ? `right: -28px;` : 'left: -28px;'}
    }
`;

export const SliderNavigationButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    border: 3px solid #2C333D;
    border-radius: 9999px;

    @media screen and (max-width: 950px) {
        border: none;
    }
`

export const SliderArrow = styled(Arrow)`
    transform: ${({ right }) => right ? 'rotate(180deg)' : 'none'};
`
import styled from "styled-components";

import { ReactComponent as Star } from '../../../../assets/svg/star.svg'

export const Container = styled.section`
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: 550px) {
        height: fit-content;
    }
`

export const TopContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 700px) {
        flex-direction: column;
    }
`

export const StarIcon = styled(Star)`
    align-self: flex-start;
    margin-bottom: 16px;

    @media screen and (max-width: 550px) {
        width: 30px;
        height: 30px;
    }
`;

export const Heading = styled.h2`
    width: 55%;
    margin-right: 48px;

    font-weight: 700;
    font-size: 72px;
    line-height: 80px;
    letter-spacing: -0.04em;

    color: #ECECEC;

    @media screen and (max-width: 1050px) {
        font-size: 56px;
        line-height: 66px;
    }

    @media screen and (max-width: 800px) {
        font-size: 40px;
        line-height: 112%;
    }

    @media screen and (max-width: 700px) {
        margin-right: 0;
        margin-bottom: 36px;
        font-size: 38px;
        line-height: 112%;
    }
`;

export const Description = styled.p`
    font-size: 20px;
    line-height: 32px;

    color: #ECECEC;

    @media screen and (max-width: 700px) {
        width: 100%;
    }

    @media screen and (max-width: 550px) {
        font-size: 16px;
        line-height: 150%;
    }
`

export const Banner = styled.img`
    height: 90vh;

    @media screen and (max-width: 550px) {
        display: none;
        margin-top: -60px;
    }
`;

export const BannerMobile = styled.img`
    display: none;
    width: 100vw;

    @media screen and (max-width: 550px) {
        display: block;
    }
`;

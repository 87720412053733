import styled from 'styled-components';

export const Wrapper = styled.section`
    padding: 0 30px;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    @media screen and (max-width: 550px) {
        padding: 0;
        padding-top: 74px;
        height: fit-content;
        align-items: flex-start;
    }
`

export const Container = styled.div`
    padding-top: 61px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    max-width: 1120px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 550px) {
        align-items: center;
    }

    @media screen and (max-width: 390px) {
        padding-top: 16px;
    }
`;

export const BgImage = styled.img`
    position: absolute;
    top: 0;
    height: 100%;

    @media screen and (max-width: 550px) {
        display: none;
    }
`

export const BgImageMobile = styled.img`
    position: absolute;
    bottom: 0;
    width: 100%;
    display: none;

    @media screen and (max-width: 550px) {
        display: block;
    }
`

export const Title = styled.h1`
    max-width: 599px;
    margin-bottom: 48px;

    font-weight: 700;
    font-size: 88px;
    line-height: 103%;
    letter-spacing: -0.03em;

    color: #ECECEC;

    @media screen and (max-width: 550px) {
        margin-bottom: 16px;
        font-size: 44px;
        line-height: 112%;
        text-align: center;
        max-width: 320px;
    }
`

export const Subtitle = styled.span`
    margin-bottom: 48px;
    

    font-weight: 600;
    font-size: 25px;
    line-height: 35px;
    letter-spacing: -0.03em;

    color: #ECECEC;

    @media screen and (max-width: 550px) {
        margin-bottom: 24px;
        font-size: 19px;
        line-height: 142%;
        text-align: center;
        color: #BCC4D0;
    }
`

export const Button = styled.button`
    text-decoration: none;
    width: fit-content;
    padding: 14px 32px;
    border-radius: 99999px;
    background-color: #ECECEC;
    margin-right: ${({ mr }) => `${(mr || 0)}px`};
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;

    color: #0A0A0F;
    border: none;
`
import React from "react";

import * as Styled from './styled';

export const Investors = () => (
    <Styled.Container>
        <Styled.Wrapper>
            <Styled.Meteor />
            <Styled.TextBlock>
                <Styled.Heading id="#contact-us">Investor Relations</Styled.Heading>
                <Styled.Text>Want to invest with us? Get more information about our governance, our latest earnings, and our long-term view on what’s ahead.</Styled.Text>
                <Styled.Button as="a" href="mailto:support@passionapp.co">Contact Us</Styled.Button>
            </Styled.TextBlock>
        </Styled.Wrapper>
    </Styled.Container>
)
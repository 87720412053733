import React from "react";

import * as Styled from './styled';


export const Features = () => (
    <Styled.Container>
        <Styled.Feature>
            <Styled.FeatureImageContainer bg="#75D3FB">
                <Styled.FeatureImage src="/img/new-feature-genres.png" />
            </Styled.FeatureImageContainer>
            <Styled.FeatureTextContainer>
                <Styled.FeatureHeading>
                    Genres
                </Styled.FeatureHeading>
                <Styled.FeatureDescription>
                Passion focuses on certain genres such as romance, paranormal urban, werewolf, and other themes similar to these. The app is beautiful to look at and a joy to use.
                </Styled.FeatureDescription>
            </Styled.FeatureTextContainer>
        </Styled.Feature>
        <Styled.Feature>
            <Styled.FeatureImageContainer bg="#2C0F66">
                <Styled.FeatureImage src="/img/feature-reader.png" />
            </Styled.FeatureImageContainer>
            <Styled.FeatureTextContainer>
                <Styled.FeatureHeading>
                    Reader
                </Styled.FeatureHeading>
                <Styled.FeatureDescription>
                The reading experience shows a history of the books you have read, and lets you create a list of the ones you want to read. You can also customize your reading experience  by changing the theme, font style, and font size of the story.
                </Styled.FeatureDescription>
            </Styled.FeatureTextContainer>
        </Styled.Feature>
    </Styled.Container>
)
import { OneLinkUrlGenerator } from './class'
import { getCookie, getParameterFromURL, getTtclid } from './utils'

export const generateOneLink = async () => {

	const onelinkGenerator = new OneLinkUrlGenerator({
		oneLinkURL: window.config.oneLink,
		pidKeysList: ['af_pid', 'utm_source'],
		pidStaticValue: 'landing_page',
		campaignKeysList: ['af_campaign', 'utm_campaign'],
		campaignStaticValue: 'campaign_error',
	})
	onelinkGenerator.setAdset('no_adset', 'adset')
	onelinkGenerator.setAd('original_url_ad', 'new_ad')

	console.log('start')
	const event_source_url =
		window.location.protocol +
		'//' +
		window.location.hostname +
		window.location.pathname
	const fbp = getCookie('_fbp')
	const fbc = getCookie('_fbc')
	const userAgent = window.navigator.userAgent
	const taboolaClickId = getParameterFromURL('taboola_clid')
	const utmSource = getParameterFromURL('utm_source')

	const ttp = getCookie('_ttp')
	const ttclid = getTtclid()
	const tt_pixel = window.config.tiktokPixel

	try {
		return fetch(
			'https://ipgeolocation.abstractapi.com/v1/?api_key=1c4acaa10922477bb9fb47f82a780a0b',
		)
			.then(res => res.json())
			.then(res => {
				const ip = res.ip_address
				const ct = res.city?.toLowerCase()
				const country = res.country_code?.toLowerCase()
				console.log(event_source_url)
				console.log(res)
				console.log('ip', ip)
				console.log('country', country)
				console.log('ct', ct)
				console.log('UserAgent', userAgent)
				console.log('fbp', getCookie('_fbp'))
				console.log('fbc', getCookie('_fbc'))
				console.log('External_id', window.id)

				if (utmSource === 'taboola') {
					const site_id = getParameterFromURL('site_id')
					const campaign_name = getParameterFromURL('utm_campaign')
					const campaign_item_id = getParameterFromURL('campaign_item_id')
					const site = getParameterFromURL('site')
					const platform = getParameterFromURL('platform')
					const campaign_id = getParameterFromURL('campaign_id')
					const click_id = getParameterFromURL('click_id')

					const params = {
						pid: 'taboola_int',
						af_siteid: site_id,
						c: campaign_name,
						af_ad_id: campaign_item_id,
						af_sub1: site,
						af_sub2: platform,
						af_c_id: campaign_id,
						af_click_lookback: '7d',
						clickid: click_id,
					}

					const query = Object.entries(params)
						.filter(([k, v]) => !!v)
						.map(v => v.join('='))
						.join('&')

					window.generateUrl = `https://app.appsflyer.com/id1568149521?${query}`
					console.log('URL:', window.generateUrl)
					return
				}

				const params = {
					fbc,
					fbp,
					external_id: window.id,
					fb_pixel: window.config.facebookPixel?.join(','),
					event_source_url,
					client_user_agent: userAgent,
					client_ip_address: ip,
					ct,
					country,
					taboola_clid: taboolaClickId,
					ttp,
					ttclid,
					tt_pixel,
				}

				const query = Object.entries(params)
					.filter(([k, v]) => !!v)
					.map(v => v.join('='))
					.join('&')

				const link = `data?${query}`

				onelinkGenerator.setDeepLinkValue(
					'original_url_deeplinkvalue',
					encodeURIComponent(link),
				)
				onelinkGenerator.setCustomParameter(
					'value_for_af_dp',
					'af_dp',
					encodeURIComponent(link),
				)

				window.campaign = onelinkGenerator.campaign
				console.log('campaign', window.campaign)
				console.log('Link urls', onelinkGenerator.generateUrl())
				return onelinkGenerator.generateUrl()
			})
	} catch (e) {
		console.log(e)

		if (utmSource === 'taboola') {
			const site_id = getParameterFromURL('site_id')
			const campaign_name = getParameterFromURL('utm_campaign')
			const campaign_item_id = getParameterFromURL('campaign_item_id')
			const site = getParameterFromURL('site')
			const platform = getParameterFromURL('platform')
			const campaign_id = getParameterFromURL('campaign_id')
			const click_id = getParameterFromURL('click_id')

			const params = {
				pid: 'taboola_int',
				af_siteid: site_id,
				c: campaign_name,
				af_ad_id: campaign_item_id,
				af_sub1: site,
				af_sub2: platform,
				af_c_id: campaign_id,
				af_click_lookback: '7d',
				clickid: click_id,
			}

			const query = Object.entries(params)
				.filter(([k, v]) => !!v)
				.map(v => v.join('='))
				.join('&')

			window.generateUrl = `https://app.appsflyer.com/id1568149521?${query}`
			window.isDone = true
			console.log(window.generateUrl)
			return
		}

		const link = `data?fbc=${fbc}&fbp=${fbp}&external_id=${
			window.id
		}&fb_pixel=${window.config.facebookPixel.join(
			',',
		)}&event_source_url=${event_source_url}&client_user_agent=${userAgent}${
			taboolaClickId ? `&taboola_clid=${taboolaClickId}` : ''
		}`
		onelinkGenerator.setDeepLinkValue(
			'original_url_deeplinkvalue',
			encodeURIComponent(link),
		)
		onelinkGenerator.setCustomParameter(
			'value_for_af_dp',
			'af_dp',
			encodeURIComponent(link),
		)

		return onelinkGenerator.generateUrl()
	}
}

export const getOneLink = async () => {

	if (window?.fbq) window.fbq('track', 'Lead')
	if (window?._tfa)
	window._tfa.push({ notify: 'event', name: 'lead', id: 1519714 })
	if (window.ttq) {
		window.ttq.track('ClickButton')
	}
	
	
	try {
		if (window.campaign && window.campaign !== 'campaign_error') {
			const campaignUrl = `https://us-central1-passion-fbe7a.cloudfunctions.net/updateUserCampaign?campaign=${window.campaign}`
			await fetch(campaignUrl)
		}
	} catch (e) {
	} finally {
		const url = await generateOneLink()
		console.log('first', url)
		if (url) {
			window.open(url, '_top', 'noopener,noreferrer')
		} else {
			window.open(window.location.href, '_top', 'noopener,noreferrer')
		}
	}
}
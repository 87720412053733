import React from 'react';
import { Footer } from '../../components/footer';
import { Header } from '../../components/header';
import { ContentContainer, Layout } from '../../components/layout';
import { Award } from '../../components/sections/author/award';
import { BecomeCreator } from '../../components/sections/author/become-creator';
import { FAQ } from '../../components/sections/author/faq';
import { Features } from '../../components/sections/author/features';
import { HowWorks } from '../../components/sections/author/how-works';
import { Intro } from '../../components/sections/author/intro';
import { Questions } from '../../components/sections/author/questions';
import { Writers } from '../../components/sections/author/writers';
import { InstallBanner } from '../../components/install-banner';

export const AuthorsPage = () => {
    return (
        <Layout>
            <Header />
            <Intro />
            <ContentContainer>
                <Features />
                <Award />
                <Writers />
                <HowWorks />
                <FAQ />
                <BecomeCreator />
                <Questions />
                <Footer />
            </ContentContainer>
            <InstallBanner />
        </Layout>
    )
}
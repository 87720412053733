import styled from "styled-components";

export const Container = styled.section`
    padding-top: 50px;
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    @media screen and (max-width: 550px) {
        height: fit-content;
        min-height: 0px;
        padding-top: 90px;
    }
`

export const Title = styled.h2`
    margin-bottom: 108px;

    font-weight: 700;
    font-size: 72px;
    line-height: 80px;
    letter-spacing: -0.04em;

    color: #ECECEC;

    @media screen and (max-width: 550px) {
        text-align: center;
        font-size: 38px;
        line-height: 112%;
        margin-bottom: 52px;
    }
`

export const ImagesContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

export const ImageWrapper = styled.div`
    padding: 0 20px 40px;
    width: 25%;

    @media screen and (max-width: 700px) {
        padding: 0 12px 20px;
    }

    @media screen and (max-width: 550px) {
        width: 33%;
        padding: 0 8px 16px;
    }
`

export const HiddenOnDesktopImgWrapper = styled.div`
    display: none;

    @media screen and (max-width: 550px) {
        display: block;
        width: 33%;
        padding: 0 8px 16px;
    }
`

export const Image = styled.img`
    width: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 20px;

    @media screen and (max-width: 550px) {
        border-radius: 8px;
    }
`
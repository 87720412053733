export const isFacebook = () => {
	if (document.referrer && document.referrer !== '') {
		return document.referrer.toLowerCase().includes('facebook')
	} else {
		return false
	}
}
export const getTtclid = () => {
	const { search } = window.location
	const params = new URLSearchParams(search)
	const ttclidFromParams = params.get('ttclid')
	const ttclidFromLocalStorage = localStorage.getItem('ttclid')

	return ttclidFromParams || ttclidFromLocalStorage
}

export const getParameterFromURL = name => {
	const url = window.location.href
	name = name.replace(/[\\[\]]/g, '\\$&')
	const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
	const results = regex.exec(url)
	if (!results) return null
	if (!results[2]) return ''
	return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const getMediaSourceValue = (
	pidKeysList,
	pidStaticValue,
	pidOverrideList,
) => {
	let pidValue = null

	for (const pidKey of pidKeysList) {
		if (pidKey != null && getParameterFromURL(pidKey)) {
			pidValue = getParameterFromURL(pidKey)
		}
	}

	if (pidValue != null) {
		// eslint-disable-next-line no-prototype-builtins
		if (pidOverrideList.hasOwnProperty(pidValue))
			pidValue = pidOverrideList[pidValue]
	} else {
		pidValue = pidStaticValue
	}
	return pidValue
}

export const getCampaignValue = (campaignKeysList, campaignStaticValue) => {
	for (const campaignKey of campaignKeysList) {
		if (getParameterFromURL(campaignKey)) {
			return getParameterFromURL(campaignKey)
		}
	}

	if (campaignStaticValue != null) {
		return campaignStaticValue
	}

	if (document.getElementsByTagName('title')[0]) {
		return document.getElementsByTagName('title')[0].innerText
	}
	return 'unknown'
}

// Create a string of param and value from
export const stringifyAfParameters = afParams => {
	let finalStr = ''

	for (const key of Object.keys(afParams)) {
		console.debug(key + '->' + afParams[key])
		if (afParams[key] != null) {
			finalStr += `&${key}=${afParams[key]}`
		}
	}
	return finalStr
}

export const setGenericParameter = (
	afParams,
	oneLinkParam,
	searchKey,
	newParamValue = null,
) => {
	const searchKeyResult = getParameterFromURL(searchKey)
	if (searchKeyResult) {
		afParams[oneLinkParam] = searchKeyResult
		console.debug(`${searchKey} found. ${oneLinkParam} = ${searchKeyResult}`)
	} else {
		if (newParamValue != null) {
			afParams[oneLinkParam] = newParamValue
			console.debug(
				`${searchKey} not found. ${oneLinkParam} = ${newParamValue}`,
			)
		} else {
			console.debug(
				`${searchKey} not found and newParamValue is null. Skipping.`,
			)
		}
	}
}

export const getParameterByName = (name, url = window.location.href) => {
	name = name.replace(/[\\[\]]/g, '\\$&')
	const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
	const results = regex.exec(url)
	if (!results) return null
	if (!results[2]) return ''
	return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const getCookie = cname => {
	const name = cname + '='
	const decodedCookie = decodeURIComponent(document.cookie)
	const ca = decodedCookie.split(';')
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i]
		while (c.charAt(0) === ' ') {
			c = c.substring(1)
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length)
		}
	}
	return ''
}

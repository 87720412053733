import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper'

import 'swiper/css'
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import * as Styled from './styled';

import './swiper.css'

export const Writers = () => {
    return (
        <Styled.Container>
            <Styled.Wrapper>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation={{
                        prevEl: '#prev-arrow',
                        nextEl: '#next-arrow',
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination
                    modules={[Pagination, Navigation, Autoplay]}
                >
                    <SwiperSlide style={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px' }}>
                        <Styled.SlideContainer>
                            <Styled.BookCover src="/img/slider-1.png" />
                            <Styled.AuthorQuote>
                                <Styled.Quote />
                                <Styled.Text>Awesome! That’s one word to put it collaborating with Passion. Modesty aside, I received some offers to have my works published on their reading apps. The Passion team are very supportive and they always inform me about the status of my stories. Looking back, I have no regrets. So look forward for more of my stories in the coming days.</Styled.Text>
                                <Styled.Author>Calixta Monroe</Styled.Author>
                            </Styled.AuthorQuote>
                        </Styled.SlideContainer>
                    </SwiperSlide>

                    <SwiperSlide style={{ display: 'flex', justifyContent: 'center', height: '100%', paddingBottom: '40px' }}>
                        <Styled.SlideContainer>
                            <Styled.BookCover src="/img/slider-2.png" />
                            <Styled.AuthorQuote>
                                <Styled.Quote />
                                <Styled.Text>It’s seems that this novel is very exciting and challenging I want to read if its freeread. Thanks a lot.</Styled.Text>
                                <Styled.Author>Maria Luisa de Vera</Styled.Author>
                            </Styled.AuthorQuote>
                        </Styled.SlideContainer>
                    </SwiperSlide>

                    <SwiperSlide style={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px' }}>
                        <Styled.SlideContainer>
                            <Styled.BookCover src="/img/slider-3.png" />
                            <Styled.AuthorQuote>
                                <Styled.Quote />
                                <Styled.Text>I really love to read this book...i feel so excited and its so fun</Styled.Text>
                                <Styled.Author>Annette Falcasantos</Styled.Author>
                            </Styled.AuthorQuote>
                        </Styled.SlideContainer>
                    </SwiperSlide>

                    <SwiperSlide style={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px' }}>
                        <Styled.SlideContainer>
                            <Styled.BookCover src="/img/slider-4.png" />
                            <Styled.AuthorQuote>
                                <Styled.Quote />
                                <Styled.Text>Quite interesting and beautiful stories friction keep improving</Styled.Text>
                                <Styled.Author>Teri Wanjiku</Styled.Author>
                            </Styled.AuthorQuote>
                        </Styled.SlideContainer>
                    </SwiperSlide>

                    {/* <SwiperSlide style={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px' }}>
                        <Styled.SlideContainer>
                            <Styled.BookCover src="/img/slider-1.png" />
                            <Styled.AuthorQuote>
                                <Styled.Quote />
                                <Styled.Text>Very nice app for readers and writers</Styled.Text>
                                <Styled.Author>Wasim Akhtar</Styled.Author>
                            </Styled.AuthorQuote>
                        </Styled.SlideContainer>
                    </SwiperSlide> */}

                    <Styled.SliderNavigationButtonContainer id="prev-arrow">
                        <Styled.SliderNavigationButton>
                            <Styled.SliderArrow />
                        </Styled.SliderNavigationButton>
                    </Styled.SliderNavigationButtonContainer>
                    <Styled.SliderNavigationButtonContainer right id="next-arrow">
                        <Styled.SliderNavigationButton >
                            <Styled.SliderArrow right />
                        </Styled.SliderNavigationButton>
                    </Styled.SliderNavigationButtonContainer>
                </Swiper>
            </Styled.Wrapper>
        </Styled.Container>
    )
}
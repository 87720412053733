import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { WRITERS_PLATFORM_LINK } from '../../constants';
import { HeaderMobile } from './header-mobile';

import * as Styled from './styled'
import { getOneLink } from '../../utils/oneLink';

export const Header = () => {
    const { pathname } = useLocation();

    return (
        <>
            <Styled.Container>
                <Styled.TopBar />
                <Styled.Wrapper>
                    <Styled.Content>
                        <Link to="/">
                            <Styled.Logo>Passion</Styled.Logo>
                        </Link>
                        <Styled.Navigation>
                            <a href="mailto:support@passionapp.co">
                                <Styled.Button mr={12}>Contact Us</Styled.Button>
                            </a>
                            {pathname.includes('/creators') ? (
                                <Styled.Button as="a" href={WRITERS_PLATFORM_LINK} accent>Become a Creator</Styled.Button>
                            ) : (
                                <Link to="/creators">
                                    <Styled.Button accent>Become a Creator</Styled.Button>
                                </Link>
                            )}

                            <Styled.Stores>
                                <button style={{backgroundColor: "transparent", border: "none"}} type="button" onClick={() => getOneLink()}>
                                    <Styled.AppleStore fill="#ffffff" />
                                </button>
                                <button style={{backgroundColor: "transparent", border: "none"}} type="button" onClick={() => getOneLink()}>
                                    <Styled.GoogleStore />
                                </button>
                            </Styled.Stores>
                        </Styled.Navigation>
                    </Styled.Content>
                </Styled.Wrapper>
            </Styled.Container>
            <HeaderMobile />
        </>
    )
}
import React from "react";

import * as Styled from './styled';

export const StoriesLibrary = () => (
    <Styled.Container>
        <Styled.IconContainer>
            <Styled.HomeIcon />
        </Styled.IconContainer>
        <Styled.TopContainer>
            <Styled.Heading>Stories library</Styled.Heading>
            <Styled.Description>
            We have hundreds of original stories and we also make recommendations based on the stories you like and you’ve rated. New stories released everyday.
            </Styled.Description>
        </Styled.TopContainer>
        <Styled.BannerImage src="/img/new-stories-library-banner.png" />
    </Styled.Container>
)
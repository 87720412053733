import styled from 'styled-components'

import { ReactComponent as Creator } from '../../../../assets/svg/creator.svg'

export const Container = styled.section`
    padding-top: 140px;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
        min-height: fit-content;
    }
`

export const CreatorIcon = styled(Creator)`
    margin-right: 20px;

    @media screen and (max-width: 1000px) {
        width: 116px;
        height: 116px;
        margin-bottom: 52px;
    }
`

export const TextContent = styled.div`
    width: 60%;
    padding: 72px 60px;
    background: #ECECEC;
    border-radius: 20px;

    @media screen and (max-width: 1000px) {
        width: 100%;
        padding: 48px 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`

export const Title = styled.h3`
    margin-bottom: 24px;

    font-weight: 700;
    font-size: 72px;
    line-height: 80px;
    letter-spacing: -0.04em;

    color: #0A0A0F;

    @media screen and (max-width: 550px) {
        font-size: 38px;
        line-height: 112%;
        text-align: center;
        margin-bottom: 28px;
    }
`

export const Text = styled.p`
    margin-bottom: 72px;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: -0.02em;

    color: #0A0A0F;

    @media screen and (max-width: 550px) {
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        margin-bottom: 48px;
    }
`

export const Button = styled.button`
    width: fit-content;
    text-decoration: none;

    padding: 14px 32px;
    border-radius: 99999px;
    background-color: #f00a9c;
    margin-right: ${({ mr }) => `${(mr || 0)}px`};
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;

    color: #FFFFFF;
    border: none;
`
import styled from "styled-components";

import { ReactComponent as MeteorIcon } from '../../../../assets/svg/meteor.svg'

// TODO: add separate component
export const Container = styled.section`
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: 550px) {
        min-height: 0;
        padding-top: 130px;
        margin-bottom: 110px;
    }
`

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 700px) {
        flex-direction: column;
        padding-bottom: 50px;
    }
`

export const Meteor = styled(MeteorIcon)`
    @media screen and (max-width: 900px) {
        width: 30%;
    }

    @media screen and (max-width: 700px) {
        width: 114px;
        height: 94px;
        margin-bottom: 64px;
    }
`;

export const TextBlock = styled.div`
    width: 54%;
    padding: 72px 60px;
    display: flex;
    flex-direction: column;
    background: #ECECEC;
    border-radius: 20px;

    @media screen and (max-width: 900px) {
        width: 60%;
        padding: 60px 40px;
    }

    @media screen and (max-width: 700px) {
        width: 100%;
        padding: 48px 24px;
        align-items: center;
    }
`

export const Heading = styled.h2`
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 72px;
    line-height: 80px;
    letter-spacing: -0.04em;

    color: #0A0A0F;

    @media screen and (max-width: 900px) {
        font-size: 60px;
        line-height: 70px;
    }

    @media screen and (max-width: 550px) {
        text-align: center;
        margin-bottom: 28px;
        font-size: 38px;
        line-height: 112%;
    }
`;

export const Text = styled.p`
    margin-bottom: 72px;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: -0.02em;

    color: #0A0A0F;

    @media screen and (max-width: 550px) {
        text-align: center;
        margin-bottom: 48px;
        font-size: 16px;
        line-height: 150%;
    }
`;

// TODO: add separate component
export const Button = styled.button`
    width: fit-content;
    padding: 14px 32px;
    border-radius: 99999px;
    background-color: #F00A9C;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-decoration: none;

    color: #FFFFFF;
    border: none;
`
import styled from "styled-components";

import { ReactComponent as RatingStar } from '../../../../assets/svg/rating-star.svg'

export const Container = styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 96px;

    @media screen and (max-width: 650px) {
        flex-direction: column;
        margin-bottom: 0;
    }
`

export const RatingBlock = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 340px;
    padding: 0px 20px;

    @media screen and (max-width: 650px) {
        margin-bottom: 48px;
    }
`;

export const Rating = styled.div`
    margin-bottom: 16px;

    display: flex;
    justify-content: center;
    align-items: center;
`

export const Star = styled(RatingStar)``;

export const Review = styled.span`
    min-height: 70px;

    margin-bottom: 16px;
    font-weight: 600;
    font-size: 25px;
    line-height: 35px;
    text-align: center;
    letter-spacing: -0.03em;

    color: #ECECEC; 

    @media screen and (max-width: 850px) {
        font-size: 20px;
        line-height: 25px;
        min-height: 75px;
    }

    @media screen and (max-width: 550px) {
        font-size: 20px;
        line-height: 142%;
        min-height: 0px;
    }
`;

export const At = styled.span`
    font-size: 14px;
    line-height: 21px;

    text-align: center;
    letter-spacing: 0.02em;

    color: #BCC4D0;

    @media screen and (max-width: 650px) {
        font-size: 13px;
        line-height: 160%;
    }
`

export const From = styled(At)`
    margin-bottom: 4px;
`
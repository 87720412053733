import styled from "styled-components";

import { ReactComponent as FanbaseIcon } from '../../../../assets/svg/fanbase.svg'
import { ReactComponent as StoriesIcon } from '../../../../assets/svg/stories.svg'
import { ReactComponent as SupportIcon } from '../../../../assets/svg/support.svg'
import { ReactComponent as CommunityIcon } from '../../../../assets/svg/community.svg'

export const Container = styled.section`
    width: 100%;
`

export const Features = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const Feature = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 120px;
    flex-direction: ${({ reverse }) => reverse ? 'row-reverse' : 'row'};

    @media screen and (max-width: 900px) {
        margin-bottom: 80px;
    }

    @media screen and (max-width: 700px) {
        margin-bottom: 68px;
        flex-direction: column;
    }
`

export const FeatureImageContainer = styled.div`
    width: 50%;
    display: flex;
    align-items: center;

    @media screen and (max-width: 700px) {
        width: 100%;
        justify-content: center;
    }
`;

export const FeatureTextContainer = styled.div`
    width: 50%;

    @media screen and (max-width: 700px) {
        width: 100%;
    }
`

export const FeatureTitle = styled.h2`
    margin-bottom: 32px;

    font-weight: 700;
    font-size: 44px;
    line-height: 60px;
    letter-spacing: -0.03em;

    color: #ECECEC;

    @media screen and (max-width: 550px) {
        font-size: 28px;
        line-height: 128%;
        margin-bottom: 24px;
        max-width: 240px;
    }
`

export const FeatureDescription = styled.p`
    font-size: 20px;
    line-height: 32px;

    color: #BCC4D0;

    @media screen and (max-width: 550px) {
        font-size: 16px;
        line-height: 150%;
    }
`

export const Fanbase = styled(FanbaseIcon)`
    margin-right: 80px;

    @media screen and (max-width: 900px) {
        margin-right: 40px;
    }

    @media screen and (max-width: 800px) {
        width: 284px;
        height: 284px;
        margin-bottom: 32px;
    }

    @media screen and (max-width: 550px) {
        margin-right: 0;
        width: 284px;
        height: 284px;
    }
`;
export const Stories = styled(StoriesIcon)`
    margin-left: 80px;

    @media screen and (max-width: 900px) {
        margin-left: 40px;
    }

    @media screen and (max-width: 800px) {
        width: 284px;
        height: 284px;
        margin-bottom: 32px;
    }

    @media screen and (max-width: 550px) {
        margin-left: 0;
        width: 284px;
        height: 284px;
    }
`;
export const Support = styled(SupportIcon)`
    margin-right: 80px;

    @media screen and (max-width: 900px) {
        margin-right: 40px;
    }

    @media screen and (max-width: 800px) {
        width: 284px;
        height: 284px;
        margin-bottom: 32px;
    }

    @media screen and (max-width: 550px) {
        margin-right: 0;
        width: 284px;
        height: 284px;
    }
`;
export const Community = styled(CommunityIcon)`
    margin-left: 80px;

    @media screen and (max-width: 900px) {
        margin-left: 40px;
    }

    @media screen and (max-width: 800px) {
        width: 284px;
        height: 284px;
        margin-bottom: 32px;
    }

    @media screen and (max-width: 550px) {
        margin-left: 0;
        width: 284px;
        height: 284px;
    }
`;
import React, { useState } from "react";

import * as Styled from './styled'
import { AccordionItem } from "./item";

export const Accordion = ({ questions }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    return (
        <Styled.Container>
            {questions.map(({ question, answer }, index) => (
                <AccordionItem
                    key={question}
                    question={question}
                    answer={answer}
                    expanded={index === activeIndex}
                    onClick={() => {
                        setActiveIndex(index === activeIndex ? null : index)
                    }}
                />
            ))}
        </Styled.Container>
    )
}

import styled from "styled-components";

import { ReactComponent as Arrow } from '../../../assets/svg/accordion-arrow.svg';

export const Container = styled.div`
    width: 100%;
    padding: 44px 76px;

    :not(:last-child) {
        border-bottom: 1px solid #2B3138;
    }

    @media screen and (max-width: 550px) {
        padding: 44px 24px 20px;
    }
`;

export const Item = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;

export const ArrowIcon = styled(Arrow)`
    margin-left: 40px;
    margin-top: 14px;
    transform: ${({ expanded }) => expanded ? 'rotate(180deg)' : 'none'};
    transition: transform ease-in 400ms;

    @media screen and (max-width: 550px) {
        height: 24px;
        width: 24px;
        margin-left: 15px;
    }
`

export const Question = styled.span`
    width: 100%;
    margin-bottom: 28px;
    font-weight: 600;
    font-size: 25px;
    line-height: 35px;
    letter-spacing: -0.03em;

    color: #ECECEC;

    @media screen and (max-width: 550px) {
        font-size: 20px;
        line-height: 142%;
        margin-bottom: 20px;
    }
`

export const AnswerContainer = styled.div`
    width: 100%;

    transition: height ease-in 400ms;

    overflow: hidden;
`;

export const Answer = styled.span`
    font-size: 20px;
    line-height: 32px;

    color: #BCC4D0;

    @media screen and (max-width: 550px) {
        font-size: 16px;
        line-height: 150%;
    }
`
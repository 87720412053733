import styled from "styled-components";

export const Container = styled.section`
    padding-top: 50px;
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: 750px) {
        padding-top: 30px;
    }

    @media screen and (max-width: 550px) {
        align-items: flex-start;
    }
`

export const Image = styled.img`
    height: 106px;
    margin-bottom: 60px;
`

export const Title = styled.h2`
    margin-bottom: 40px;

    font-weight: 700;
    font-size: 88px;
    line-height: 103%;

    text-align: center;
    letter-spacing: -0.03em;

    color: #ECECEC;

    @media screen and (max-width: 550px) {
        margin-bottom: 36px;
        text-align: left;
        font-size: 38px;
        line-height: 112%;
    } 
`

export const Text = styled.p`
    padding: 0 20px;
    max-width: 800px;
    margin: 52px;
    font-size: 20px;
    line-height: 32px;
    text-align: center;

    color: #ECECEC;

    @media screen and (max-width: 550px) {
        padding: 0;
        margin: 0;
        margin-bottom: 36px;
        text-align: left;
        font-size: 16px;
        line-height: 150%;
    }
`

export const Subtitle = styled.h4`
    margin-bottom: 16px;

    font-weight: 600;
    font-size: 25px;
    line-height: 35px;
    text-align: center;
    letter-spacing: -0.03em;

    color: #ECECEC;

    @media screen and (max-width: 550px) {
        text-align: left;
        font-size: 20px;
        line-height: 142%;
        margin-bottom: 12px;
    }
`
import styled from "styled-components";

import { ReactComponent as HomeIconElement } from '../../../../assets/svg/home.svg'

export const Container = styled.section`
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: 550px) {
        height: fit-content;
    }
`

export const IconContainer = styled.div`
    width: 100%;
`

export const HomeIcon = styled(HomeIconElement)`
    margin-bottom: 21px;

    @media screen and (max-width: 550px) {
        margin-bottom: 19px;
        width: 26px;
        height: 26px;
    }
`

export const TopContainer = styled.div`
    margin-bottom: 46px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 550px) {
        margin-bottom: 68px;
        flex-direction: column;
    }
`

export const Heading = styled.h2`
    width: 60%;
    font-weight: 700;
    font-size: 72px;
    line-height: 80px;
    letter-spacing: -0.04em;
    color: #ECECEC;

    @media screen and (max-width: 800px) {
        font-size: 48px;
        line-height: 112%;
    }

    @media screen and (max-width: 550px) {
        margin-bottom: 36px;
        width: 100%;
        font-size: 38px;
        line-height: 112%;
    }
`

export const Description = styled.p`
    width: 100%;
    font-size: 20px;
    line-height: 32px;
    color: #ECECEC;

    @media screen and (max-width: 550px) {
        width: 100%;
        font-size: 16px;
        line-height: 150%;
    }
`

export const BannerImage = styled.img`
    width: 100%;

    @media screen and (max-width: 550px) {
        width: calc(100% + 400px);
        /* margin-left: -100px; */
    }
`;

import React  from "react";

import * as Styled from './styled'
import { Accordion } from "../../../accordion";

const QUESTIONS = [
    {
        question: 'Will I have to delete my account on other writing platforms if I sign up with you?',
        answer: "No, you won't. It’s a small industry. And after all, we all want the same thing: to encourage people to read stories.",
    },
    {
        question: "How will I receive my income?",
        answer: "Passion has three type of payment methods: Paypal, Payoneer and Bank Transfer.",
    },
    {
        question: "Do I need to make my own cover?",
        answer: "No, we’ll do it for you!",
    },
    {
        question: "What genres are you interested in?",
        answer: "Any genre that contains the element of romance.",
    },
    {
        question: "What is the required minimum number of words?",
        answer: "60, 000 words. The longer the story is, the higher the potential income will be.",
    },
    {
        question: "Are you looking for new or old manuscripts?",
        answer: "Both are accepted. But we suggest you give us your best.",
    },
    {
        question: "I’ve never made an income from writing before. Would anyone actually pay to read my work?",
        answer: "Yes. Passion has a broad readership and an industry-leading promotion and marketing system. The writer’s hard work deserves the financial reward.",
    },
    {
        question: "I am a student who is under a lot of pressure at the end of the semester. I’m afraid that I don’t have enough experience to devote myself to writing during this time.",
        answer: "Passion full time writers can work around their own schedule, so you don’t need to worry. Many of our creators are students and they enjoy working with Passion.",
    },
]

export const FAQ = () => (
    <Styled.Container>
        <Styled.FaqIcon />
        <Styled.Title>
            Creator FAQS
        </Styled.Title>
        <Styled.Subtitle>Contact us to and we will answer all your questions</Styled.Subtitle>
        <Styled.Button as="a" href="mailto:support@passionapp.co">Contact Us</Styled.Button>
        <Accordion questions={QUESTIONS} />
    </Styled.Container>
)
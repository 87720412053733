import React from "react";

import * as Styled from './styled'
import { getOneLink} from '../../../../utils/oneLink';

export const Intro = () => {

    return (
        <Styled.Wrapper>
            <Styled.Container>
                <Styled.ContentContainer>
                    <Styled.Heading>Top fiction stories in your pocket</Styled.Heading>
                    <Styled.Subheading>Enjoy reading anytime, anywhere</Styled.Subheading>
                    <Styled.ButtonsContainer>
                        <Styled.StoreButton as="button" onClick={() => getOneLink()}>
                            <Styled.AppleStore fill="#0A0A0F" />
                            App Store
                        </Styled.StoreButton>
                        <Styled.StoreButton as="button" onClick={() => getOneLink()}>
                            <Styled.GoogleStore />
                            Google Play
                        </Styled.StoreButton>
                    </Styled.ButtonsContainer>
                </Styled.ContentContainer>

                <Styled.SideImage src="/img/new-intro.png" />
                <Styled.MobileImage src="/img/new-intro-mobile.png" />
            </Styled.Container>
        </Styled.Wrapper>
    )
}
import React from "react";

import * as Styled from './styled'
import { getOneLink } from "../../utils/oneLink";

export const Footer = () => {
    return (
        <Styled.Container>
            <Styled.Wrapper>
                <Styled.LeftBlock>
                    <Styled.Logo />
                    <Styled.RightsText>All rights reserved</Styled.RightsText>
                </Styled.LeftBlock>
                <Styled.RightBlock>
                    <Styled.GetAppText id="get-app">Get App</Styled.GetAppText>
                    <Styled.Stores>
                        <Styled.StoreButton as="button" onClick={() => getOneLink()}>
                            <Styled.AppleStore fill="#0A0A0F" />
                            App Store
                        </Styled.StoreButton>
                        <Styled.StoreButton as="button" onClick={() => getOneLink()}>
                            <Styled.GoogleStore />
                            Google Play
                        </Styled.StoreButton>
                    </Styled.Stores>
                </Styled.RightBlock>
    
                <Styled.MobileBlock>
                    <Styled.Email href="mailto:support@passionapp.co">support@passionapp.co</Styled.Email>
                    <Styled.Terms>
                        <Styled.Term>Terms Of Use</Styled.Term>
                        <Styled.TermDivider />
                        <Styled.Term>Terms Of Use</Styled.Term>
                    </Styled.Terms>
    
                    <Styled.RightsReserved>All rights reserved 2023</Styled.RightsReserved>
                </Styled.MobileBlock>
            </Styled.Wrapper>
            <Styled.Year>2023</Styled.Year>
        </Styled.Container>
    )
}
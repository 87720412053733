import React from 'react';

import * as Styled from './styled';

export const Features = () => (
    <Styled.Container>
        <Styled.Features>
            <Styled.Feature>
                <Styled.FeatureImageContainer>
                    <Styled.Fanbase />
                </Styled.FeatureImageContainer>
                <Styled.FeatureTextContainer>
                    <Styled.FeatureTitle>
                        Cultivate your fanbase
                    </Styled.FeatureTitle>
                    <Styled.FeatureDescription>
                        Share your story. Click with your community. Stories create connections and Passion is where creators find fans, grow their audience, and get rewarded for their creativity.
                    </Styled.FeatureDescription>
                </Styled.FeatureTextContainer>
            </Styled.Feature>

            <Styled.Feature reverse>
                <Styled.FeatureImageContainer>
                    <Styled.Stories />
                </Styled.FeatureImageContainer>
                <Styled.FeatureTextContainer>
                    <Styled.FeatureTitle>
                        Champion your stories
                    </Styled.FeatureTitle>
                    <Styled.FeatureDescription>
                        Build your brand as a writer. Use tags and engagement tools to grow your own fandom. Passion is always creator-first, spotlighting your stories through in-platform promo and beyond into entertainment and publishing
                    </Styled.FeatureDescription>
                </Styled.FeatureTextContainer>
            </Styled.Feature>

            <Styled.Feature>
                <Styled.FeatureImageContainer>
                    <Styled.Support />
                </Styled.FeatureImageContainer>
                <Styled.FeatureTextContainer>
                    <Styled.FeatureTitle>
                        Full support from our team
                    </Styled.FeatureTitle>
                    <Styled.FeatureDescription>
                        We provide the tools to help writers discover the best way to market themselves and their content. Using our data-driven approach to story optimization and growth
                    </Styled.FeatureDescription>
                </Styled.FeatureTextContainer>
            </Styled.Feature>

            <Styled.Feature reverse>
                <Styled.FeatureImageContainer>
                    <Styled.Community />
                </Styled.FeatureImageContainer>
                <Styled.FeatureTextContainer>
                    <Styled.FeatureTitle>
                        Be part of our writing community
                    </Styled.FeatureTitle>
                    <Styled.FeatureDescription>
                        1000+ writers have already join us. We keep working on building a community of authors who uplift one another and make a positive difference in the publishing world.
                    </Styled.FeatureDescription>
                </Styled.FeatureTextContainer>
            </Styled.Feature>
        </Styled.Features>
    </Styled.Container>
)
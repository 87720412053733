import styled from "styled-components";

import { ReactComponent as Star } from '../../../../assets/svg/star.svg'

export const Container = styled.section`
    position: relative;
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 550px) {
        flex-direction: column;
        margin-bottom: 100px;
    }
`

export const StarIcon = styled(Star)`
    align-self: flex-start;

    @media screen and (max-width: 550px) {
        width: 30px;
        height: 30px;
        margin-bottom: 16px;
    }
`;

export const Heading = styled.h2`
    width: 55%;
    margin-right: 48px;

    font-weight: 700;
    font-size: 72px;
    line-height: 80px;
    letter-spacing: -0.04em;

    color: #ECECEC;

    @media screen and (max-width: 550px) {
        width: 100%;
        font-size: 38px;
        line-height: 112%;
        margin-right: 0;
        margin-bottom: 36px;
    }
`;

export const Description = styled.p`
    font-size: 20px;
    line-height: 32px;

    color: #ECECEC;

    @media screen and (max-width: 550px) {
        font-size: 16px;
        line-height: 150%;
    }
`

export const Banner = styled.img`
    height: 60vh;

    @media screen and (max-width: 550px) {
        height: 40vh;
    }
`;

export const Video = styled.video`
    position: absolute;
    bottom: 26%;
    border: 12px solid #75D3FB;
    border-radius: 32px;
    height: 60vh;

    @media screen and (max-width: 550px) {
        height: 50vh;
        bottom: 30%;
    }
`

export const Author = styled.span`
    margin-top: 76px;
    margin-bottom: 26px;
    max-width: 344px;
    font-weight: 700;
    font-size: 44px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.03em;

    color: #FFFFFF;

    @media screen and (max-width: 550px) {
        max-width: 240px;
        font-size: 28px;
        line-height: 128%;
        margin-bottom: 24px;
    }
`;

export const AuthorDescription = styled.span`
    max-width: 344px;
    font-size: 20px;
    line-height: 32px;
    text-align: center;

    color: #64738A;

    @media screen and (max-width: 550px) {
        margin-bottom: 64px;
        max-width: 260px;
        font-size: 16px;
        line-height: 150%;
    }
`;
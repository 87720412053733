import React from "react";
import { WRITERS_PLATFORM_LINK } from "../../../../constants";

import * as Styled from './styled'

export const Intro = () => {
    return (
        <Styled.Wrapper>
            <Styled.BgImage src="/img/authors-banner.png" />
            <Styled.BgImageMobile src="/img/authors-banner-mobile.png" />
            <Styled.Container>
                <Styled.Title>
                    Rewrite your future
                </Styled.Title>
                <Styled.Subtitle>
                    It all starts here!
                </Styled.Subtitle>
                <Styled.Button as="a" href={WRITERS_PLATFORM_LINK}>Become a Creator</Styled.Button>
            </Styled.Container>
        </Styled.Wrapper>
    )
}
import styled from 'styled-components'

import { ReactComponent as Faq } from '../../../../assets/svg/faq.svg';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const FaqIcon = styled(Faq)`
    margin-bottom: 58px;

    @media screen and (max-width: 550px) {
        width: 100px;
        height: 100px; 
        margin-bottom: 48px; 
        border-radius: 9999px;
    }
`;

export const Title = styled.h2`
    margin-bottom: 28px;

    font-weight: 700;
    font-size: 72px;
    line-height: 80px;
    letter-spacing: -0.04em;

    color: #ECECEC;

    @media screen and (max-width: 550px) {
        margin-bottom: 24px;
        text-align: center;
        font-size: 38px;
        line-height: 112%;
    }
`

export const Subtitle = styled.span`
    margin-bottom: 57px;
    
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    letter-spacing: -0.02em;

    color: #BCC4D0;

    @media screen and (max-width: 550px) {
        font-size: 19px;
        line-height: 142%;
        margin-bottom: 48px;
    }
`

export const Button = styled.button`
    width: fit-content;
    margin-bottom: 88px;
    text-decoration: none;

    padding: 14px 32px;
    border-radius: 99999px;
    background-color: #f00a9c;
    margin-right: ${({ mr }) => `${(mr || 0)}px`};
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;

    color: #FFFFFF;
    border: none;

    @media screen and (max-width: 550px) {
        margin-bottom: 81px;
    }
`
import React from "react";

import * as Styled from './styled';

export const Stories = () => (
    <Styled.Container>
        <Styled.Title>Our Top Stories</Styled.Title>
        <Styled.ImagesContainer>
            <Styled.ImageWrapper>
                <Styled.Image src="/img/book-1.png" />
            </Styled.ImageWrapper>
            <Styled.ImageWrapper>
                <Styled.Image src="/img/book-2.png" />
            </Styled.ImageWrapper>
            <Styled.ImageWrapper>
                <Styled.Image src="/img/new-book-3.png" />
            </Styled.ImageWrapper>
            <Styled.ImageWrapper>
                <Styled.Image src="/img/new-book-4.png" />
            </Styled.ImageWrapper>
            <Styled.ImageWrapper>
                <Styled.Image src="/img/new-book-5.png" />
            </Styled.ImageWrapper>
            <Styled.ImageWrapper>
                <Styled.Image src="/img/new-book-6.png" />
            </Styled.ImageWrapper>
            <Styled.ImageWrapper>
                <Styled.Image src="/img/new-book-7.png" />
            </Styled.ImageWrapper>
            <Styled.ImageWrapper>
                <Styled.Image src="/img/book-8.png" />
            </Styled.ImageWrapper>
            <Styled.HiddenOnDesktopImgWrapper>
                <Styled.Image src="/img/book-9.png" />
            </Styled.HiddenOnDesktopImgWrapper>
        </Styled.ImagesContainer>
    </Styled.Container>
)